*{
    margin:0;
    padding:0;
}

.no-margin{
    margin:0 !important;
}

.bar-header{
    padding:0 20px;
    width: 100%;
    // width:'100%', paddingLeft:20, paddingRight:20
}

.content-wrapper{
    padding:40px 24px;
}


.row-login{
    align-items: center;
    min-height: 100vh;
}

.wrapper-login{
    background: #FFF;
    border-radius: 20px;
    padding:25px;
}